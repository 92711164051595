.profPic {
  font-size: 40px;
}
.profileHide {
  display: none;
}
.profilePage {
  bottom: 0;
  top: 0;
  right: 0px;
  width: min(100vw, 600px);
  position: fixed;
  transition: display 900ms;
  z-index: 60;
  background-color: var(--dark);
  color: var(--light);
  font-size: 24px;
}

.friendItem {
  display: flex;
}

.profileNav {
  width: 100%;
  /* background-color: rgb(171, 193, 184); */
  display: flex;
}

.profileNavItemActive {
  text-align: center;
  justify-content: center;
  /* border: 1px solid rgb(222, 222, 222); */
  color: var(--dark);
  background-color: var(--pine);
  flex: 1;
  border-radius: 5px;
  padding: 5px;
}

.profileNavItem {
  text-align: center;
  justify-content: center;
  border: 1px solid var(--dark);
  color: var(--light);
  flex: 1;
  border-radius: 5px;
  padding: 5px;
}

.friendSearch {
  height: 30px;
  width: 50%;
  margin: 0 20px;
  padding: 2px;
  font-weight: bold;
  border-radius: 5px;
  background-color: var(--pine);
  font-size: 24px;
  color: rgb(65, 65, 65);
}
.closeButton {
  padding: 5px;
  color: var(--pine);
  margin: 0 10px;
}
.friendItem {
  background: var(--pine);
  padding: 20px;
  margin: 10px 0;
  border-radius: 10px;
  line-height: 100%;
  align-items: center;
}
.friendButton {
  color: var(--pine);
  background: var(--light);
  right: 20px;
  height: 40px;
  position: absolute;
  width: 40px;
  border-radius: 15px;
  padding: 0;
}
.profileSection {
  padding: 10px;
}
@media screen and (max-width: 600px) {
  .profileFullHeight {
    height: calc(100vh - 80px);
    bottom: 0;
    margin-top: 80px;
  }
}
