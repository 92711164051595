:root {
  --pine: #3e8fb0;
  --rose: #ea9a97;
  --purple-white: #edecf9;
  --dark: #080816;
  --light: #ffffff;
}
html {
  background-image: linear-gradient(
      160deg,
      rgba(0, 0, 0, 0.92) 40%,
      rgba(62, 143, 176, 0.95) 130%
    ),
    url("../resources/crosswordPic.png");
  background-size: cover;
}
html,
body {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow: scroll;
}
* {
  padding: 0;
  margin: 0;
  /* font-family: 'Roboto', sans-serif; */
  /* font-family: "Barlow Semi Condensed", sans-serif; */
  font-family: "Inter", sans-serif;
  font-weight: 300;
}

.logIn {
  padding: 50px;
  width: 250px;
  font-size: 35px;
  margin: 200px;
}

h2 {
  color: var(--light);
}

.sudoku {
  margin: 100px 0 0 300px;
}

.sudokuGridItem {
  flex: 1;
  position: relative;
  border: 0.5px solid #0e0e16;
  font-size: 40px;
  text-align: center;
  justify-content: center;
}
Link {
  display: block;
}

.sudokuGridRow *:nth-child(3n) {
  box-shadow: inset -3px 0px 0px 0px rgb(48, 48, 48); /* Border bottom */
}
.sudokuGridRow:nth-child(3n + 1) * {
  box-shadow: inset 0px 3px 0px 0px rgb(48, 48, 48); /* Border bottom */
}
.sudokuGridRow:nth-child(3n + 1) *:nth-child(3n) {
  box-shadow: inset -3px 3px 0px 0px rgb(48, 48, 48); /* Border bottom */
}

button {
  background-color: var(--pine);
  color: rgb(48, 48, 48);
  padding: 15px;
  box-shadow: none;
  border: none;
  border-radius: 100px;
  margin: 5px;
  font-size: 24px;
}

.homepageButton:hover {
  background-color: #edecf9;
}
.navButton:hover {
  border-bottom: 5px solid var(--light);
}

h3 {
  color: rgb(222, 222, 222);
  display: inline;
  width: 100%;
  padding-left: 150px;
  font-size: 20px;
  opacity: 0.8;
  margin-top: -100px;
}
.whiteButton {
  background: var(--light) !important;
  color: var(--dark) !important;
}

.sudokuGridRow {
  flex: 1;
  display: flex;
  flex-direction: row;
}

.gridItem {
  flex: 1;
  position: relative;
  border: 0.5px solid #0e0e16;
}

button:active {
  transform: translateY(4px);
}

.bigTitle {
  width: 150px;
  font-size: 64px;
}
input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select:focus,
textarea {
  font-size: 16px;
}
@media screen and (max-width: 600px) {
  html,
  body {
    overflow: hidden;
  }
  .logIn {
    margin: 50px;
  }
}
