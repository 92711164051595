.crosswordList {
  margin: 20px 20px 0;
  display: flex;
  flex-direction: column;
}
.crosswordListItem {
  color: var(--dark);
  display: flex !important;
  background-color: var(--pine);
  padding: 20px;
  border-radius: 20px;
  margin: 10px;
}
.crosswordListItem *:nth-child(1) {
  text-decoration: none;
  border: none;
  color: var(--dark);
  flex: 5;
}
.removeButton {
  padding: 5px;
  flex: 1;
  background: var(--purple-white);
  text-align: center;
  height: 30px;
  line-height: 30px;
  border-radius: 10px;
  font-size: 40px;
  width: 30px;
}
.subHomePage {
  display: flex;
  width: 100vw;
  height: calc(100vh - 220px);
  overflow-y: scroll;
  overflow-x: hidden;
  justify-content: center;
}

.crosswordListWrapper {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 320px;
  font-size: 24px;
}

.homePage {
  margin-top: 220px;
  display: flex;
}

.homepageButton {
  height: 80px;
  width: 300px;
  margin: 20px;
}
.heroSection {
  width: 500px;
  margin: 0 80px;
}

.heroText {
  color: var(--light);
  font-size: 18px;
}

@media screen and (max-width: 600px) {
  .heroText {
    display: none;
  }
  .homePage {
    margin-top: 20px !important;
    width: 100%;
    flex-direction: column;
  }
  .subHomePage {
    height: calc(100vh - 240px);
  }
  .heroText {
    max-width: 350px;
  }
  .heroSection {
    width: min(100vw, 400px);
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin-inline: auto;
  }
  .bigTitle {
    width: 100vw;
    text-align: center;
  }
}
