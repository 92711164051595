.scores {
  margin: 10px 0;
  background-color: var(--purple-white);
  height: 270px;
  border-radius: 10px;
  overflow: hidden;
}
.modal {
  position: absolute;
  width: min(90vw, 400px);
  height: min(90vh, 450px);
  background: var(--purple-white);
  z-index: 1000;
  border-radius: 50px;
  left: 50%;
  right: 50%;
  margin-left: max(-45vw, -200px);
  margin-top: 5vw;
  box-shadow: 0 0 50px 80px var(--pine);
}
.modalCloseButton {
  font-size: 40px;
  position: absolute;
  bottom: 30px;
  right: 30px;
}
.innerModal {
  font-size: 24px;
  padding: 20px;
}
.modalItem {
  display: flex;
  background: var(--pine);
  margin: 10px 5px;
  color: var(--light);
  font-weight: 700;
  padding: 10px;
  border-radius: 20px;
}
.modalItem * {
  margin-right: 30px;
}
.modalTitle {
  color: var(--pine);
  padding: 10px;
  font-size: 30px;
  text-align: center;
  border-bottom: 5px solid var(--pine);
}
.actions {
  margin: 10px 0;
  background-color: var(--purple-white);
  height: 220px;
  border-radius: 10px;
  overflow: hidden;
}
.actionContent {
  padding: 10px;
  bottom: 0;
  position: relative;
  text-align: center;
}
.actionButton {
  font-size: 20px;
  bottom: 0;
  padding: 10px;
  position: relative;
}
.actionTitle {
  border-bottom: 2px solid var(--dark);
  padding: 5px;
  font-size: 24px;
  background: var(--rose);
}
.scoreItem {
  font-size: 20px;
  display: block;
  margin: 5px;
}
.scoreItem * {
  display: inline;
  margin-left: 10px;
  color: var(--pine);
}
.interface {
  margin: 0 10px;
  width: min(25%, 280px);
}

.actionPanel {
  width: 800px;
  height: 50px;
  border-radius: 50px;
  display: flex;
  background: var(--purple-white);
  margin: 5px auto;
}

.actionPanel *:nth-child(1) {
  border-radius: 50px 0 0 50px;
}

.actionPanel * {
  padding: 0px 10px;
  height: 50px;
  line-height: 50px;
}
.actionPanel *:hover {
  background: var(--rose);
}
@media screen and (max-width: 600px) {
  .interface {
    display: flex;
    width: 100vw;
    bottom: 0;
    gap: 10px;
    margin: 10px 0;
  }
  .actions {
    width: 50%;
    margin: 0;
    border-radius: 10px 0 0 10px;
    height: calc(100vh - min(400px, 100vw) - 80px);
  }
  .scores {
    width: 50%;
    margin: 0;
    border-radius: 0 10px 10px 0;
    height: calc(100vh - min(400px, 100vw) - 80px);
  }
  .actionPanel {
    top: 5px;
    right: 15px;
    position: fixed;
    width: calc(100% - 100px) !important;
  }
}
