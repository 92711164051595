.navbar * {
  margin: 0 20px;
  line-height: 40px;
  text-decoration: none;
  font-size: 40px;
}
.menuButton {
  fill: var(--light);
  color: var(--light);
  width: 100px;
}

.navButton {
  color: white;
  line-height: 75px;
  height: 80px;
}

.navbar {
  align-items: center;
  margin-top: 40px;
  width: 100%;
  position: fixed;
  top: 0;
  display: flex;
}
img {
  max-width: 100%;
}
.title {
  margin-left: 30px;
  width: 80px;
  height: 80px;
  border-radius: 40px;
  display: inline-block;
}

.title :hover {
  border-bottom: 0px solid white !important;
}

@media screen and (max-width: 600px) {
  .homePage {
    padding-top: 0 !important;
  }
  .navbar {
    position: relative;
    display: block;
    margin-top: 10px;
  }
  .navbar .menuButton {
    position: absolute;
    right: 0;
    top: 0;
  }
  .navbar .navButton {
    float: none;
    display: block;
    text-align: left;
  }
  .title {
    width: 50px;
    height: 50px;
    margin-left: 5px;
  }
}
