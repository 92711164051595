input:focus-visible {
  outline: none;
}
.grid {
  aspect-ratio: 1/1;
  width: min(50%, 500px);
  height: 500px;
  display: flex;
  flex-direction: column;
  margin: 10px;
}

.noCrosswordLink {
  padding: 30px;
  display: block;
  text-decoration: none;
  background: var(--pine);
  font-size: 50px;
  border-radius: 100px;
  z-index: 100000;
  width: 250px;
  margin: 300px;
  color: rgb(48, 48, 48);
}
.clueList {
  overflow: hidden;
  margin: 10px;
  background-color: var(--purple-white);
  border: 2px solid rgb(48, 48, 48);
  border-radius: 10px;
}
.clueListWrapper {
  width: min(25%, 300px);
}

.border {
  width: 100%;
  height: 100%;
  z-index: 30;
  position: absolute;
}

.active {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 10;
  opacity: 0.7;
}

.boxContent {
  width: 100%;
  height: 100%;
  line-height: 100%;
  z-index: 100;
  position: absolute;
  align-items: center;
  text-align: center;
}
.activeClue {
  background: #ea9a97;
  font-weight: bold;
  padding: 5px;
}
.clue {
  font-weight: bold;
  padding: 5px;
}

.clueNum {
  font-weight: bold;
  width: 100%;
  font-size: 10px;
  z-index: 50;
  position: absolute;
}
.innerCrossword {
  display: flex;
  width: min(100vw, 1200px);
  margin: auto;
}
.correct {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 50;
}

.challenge {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 60;
}

.userColor {
  width: 100%;
  height: 100%;
  position: absolute;
}

.activeLine {
  width: 100%;
  height: 100%;
  position: absolute;
}

.gridRow {
  flex: 1;
  display: flex;
  flex-direction: row;
}

.clueHeader {
  border-radius: 10px 10px 0 0;
  font-size: 20px;
  padding: 5px;
  width: 290px;
  position: sticky;
  background: var(--rose);
  border-bottom: 2px solid var(--dark);
  top: 0;
  height: 25px;
}
.innerClueList {
  overflow: scroll;
  height: 200px;
}
.gridInput {
  height: 100%;
  caret-color: transparent;
  width: 100%;
  z-index: 500;
  background: none;
  border: none;
  position: absolute;
  font-size: 20px;
}

.crossword {
  margin-top: 100px;
  color: rgb(48, 48, 48) !important;
  width: 100vw;
  margin-inline: auto;
}

@media screen and (max-width: 600px) {
  input :focus ~ .grid {
    width: 100px !important;
  }
  .grid {
    width: min(400px, 100vw);
    height: min(400px, 100vw);
    margin: 70px auto 0;
    overflow-y: scroll;
  }
  h3 {
    display: none;
  }
  .crossword {
    margin: -60px 0 0 0;
  }
  .innerCrossword {
    flex-direction: column;
  }
  .clue {
    display: none !important;
  }
  .clueHeader {
    display: none;
  }
  .clueText {
    display: block;
    width: 80%;
    overflow-y: scroll;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none;
  }
  ::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
  .activeClue {
    position: fixed;
    display: flex;
    top: 10px;
    left: 85px;
    right: 15px;
    height: 40px;
    border-radius: 40px;
    text-align: center;
    justify-content: center;
  }
  .innerClueList {
    height: 0;
  }
  .clueListWrapper {
    height: 0;
    overflow: hidden;
  }
  .noCrosswordLink {
    margin: 100px auto;
    font-size: 30px;
    width: 150px;
    padding: 30px;
  }
}
@media screen and (max-height: 500px) {
  .innerCrossword {
    height: calc(100vh - 60px);
    overflow-y: scroll;
    display: block;
    margin-top: 70px;
  }
  .grid {
    margin-top: 0;
  }
  .scores,
  .actions {
    display: none;
  }
}
